import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import { FluidObject } from 'gatsby-image';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import PotencyCard from './PotencyCard';
import { convertToMobileVW, convertToVW } from 'shared/utils';

interface PotencySection {
  contentfulPotencySection: {
    title: string;
    subheader: {
      subheader: string;
    };
    potencyCards: {
      id: string;
      title: string;
      description: {
        description: string;
      };
      backgroundTexture: {
        fluid: FluidObject;
      };
    }[];
  };
}

const PotencyContainer = () => {
  const {
    contentfulPotencySection: { title, subheader, potencyCards },
  }: PotencySection = useStaticQuery(graphql`
    query PotencySectionQuery {
      contentfulPotencySection {
        title
        subheader {
          subheader
        }
        potencyCards {
          id
          title
          description {
            description
          }
          backgroundTexture {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  return (
    <div className={css(styles.container)}>
      <h3 className={css(styles.h3)}>{title}</h3>
      <p className={css(styles.p)}>{subheader.subheader}</p>
      <ul className={css(styles.listContainer)}>
        {potencyCards.map(
          (
            { description: { description }, id, title, backgroundTexture },
            index
          ) => {
            return (
              <PotencyCard
                key={id}
                title={title}
                description={description}
                backgroundTexture={backgroundTexture}
                extraStyles={styles.upper}
                index={index}
              />
            );
          }
        )}
      </ul>
    </div>
  );
};

export default PotencyContainer;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    backgroundColor: black,
    color: white,
    width: '100%',
    height: 'fit-content',
    zIndex: 10,
    margin: 0,
    paddingTop: '100px',
    paddingBottom: '60px',
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
  },
  h3: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Compressed Standard',
    fontWeight: 'normal',
    fontSize: 70,
    zIndex: 1,
    textAlign: 'center',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
    },
  },
  p: {
    color: white,
    fontFamily: 'Titling Gothic FB Normal Regular',
    marginTop: '1.5rem',
    fontSize: 12,
    maxWidth: '800px',
    lineHeight: 1.5,
    marginBottom: '7rem',
    zIndex: 1,
    textAlign: 'center',
    margin: '0 auto',
    [BREAKPOINTS.TABLET]: {
      marginTop: '.5rem',
      marginBottom: '2.5rem',
      width: '90%',
    },
  },
  listContainer: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: '1fr',
    zIndex: 10,
    backgroundColor: black,
    color: white,
    paddingTop: convertToVW(100),
    margin: '0 5%',
    [BREAKPOINTS.TABLET]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
});
