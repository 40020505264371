import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import SEO from 'components/SEO/SEO';
import PageTopSection from 'components/PageTopSection/PageTopSection';
import FlavorContainer from 'components/FlavorSection/FlavorContainer';
import PotencyContainer from 'components/PotencySection/PotencyContainer';
// import Selector from 'components/Selector/Selector';
import { convertToVW, convertToMobileVW } from 'shared/utils';
import VideoSection from 'components/VideoSection/VideoSection';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import { ContentfulHomePage } from 'types/shared';
import { Text } from 'components/Text/Text';
import HighTalesSection from 'components/HighTales/HighTalesSection';
import LogoCarousel from 'components/LogoCarousel/LogoCarousel';

const bg = require('images/herov2.jpg');
const posterbg = require('images/Poster_Close-up_2.png');

type Props = {
  data: {
    contentfulHomePage: ContentfulHomePage;
  };
  location: {
    pathname: string;
  };
};

const HomePage = ({ data, location: { pathname } }: Props) => {
  const {
    topSection: { bottomSubheader, mainHeader, topSubheader },
    processCtaVideo,
    productCtaImage,
    editorialCtaImage,
  } = data.contentfulHomePage;

  return (
    <main>
      <SEO title="Home" pathname={pathname} />
      <div>
        <PageTopSection
          mainHeader={mainHeader}
          topSubheader={topSubheader}
          bottomSubheader={bottomSubheader}
        />
      </div>
      <div className={css(styles.saturnContainer)}>
        <iframe
          src="https://storage.googleapis.com/monogram-develop/threed/iframe_text3d_transparent.html"
          id="saturnIframe"
          title="contains a 3D jar of monogram product"
          className={css(styles.saturn)}
        />
      </div>
      <FlavorContainer />
      <PotencyContainer />
      {/* <Selector /> */}
      <div className={css(styles.carouselContainer)}>
        <iframe
          src="https://storage.googleapis.com/monogram-webgl/carousel.html"
          id="productCarouselIframe"
          title="contains a 3D with all four products"
          className={css(styles.carousel)}
          scrolling="no"
        />

        <div className={css(styles.buttonWrapper, styles.iframeCheat)}>
          <a href="/products">
            <button className={css(styles.button)}>
              <Text
                text="Discover All Products"
                extraStyle={styles.buttonText}
              />
            </button>
          </a>
        </div>
      </div>
      <LogoCarousel />

      {/* <div className={css(styles.productCtaContainer)}>
        <img
          id="herov3"
          className={css(styles.productCta)}
          src={posterbg}
          alt="Reviewed by Accessible 360"
        />
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.subheaderposter)}>
            LIMITED EDITION FINE-ART PRINT COLLECTION
          </p>
          <h1 className={css(styles.title)}>THE GOOD LIFE, REDEFINED</h1>
          <div className={css(styles.buttonWrapperHardcode)}>
            <a href="https://caliva.com/monogram" target="_blank">
              <button className={css(styles.buttonHardcode)}>
                <p className={css(styles.buttonTextHardcode)}>shop products</p>
              </button>
            </a>
          </div>
        </div>
      </div> */}

      <div className={css(styles.productCtaContainer)}>
        <Img
          fluid={productCtaImage.fluid}
          className={css(styles.productCta)}
          alt="Products"
        />
        <div className={css(styles.buttonWrapper)}>
          <a href="https://caliva.com/monogram" target="_blank">
            <button className={css(styles.button)}>
              <Text text="shop now" extraStyle={styles.buttonText} />
            </button>
          </a>
        </div>
      </div>

      <HighTalesSection />
      <div className={css(styles.processCta)}>
        <VideoSection
          buttonText="Monogram Process"
          hasTextOverlay={false}
          video={processCtaVideo}
          isProcessCta
        />
      </div>

      <div className={css(styles.productCtaContainer)}>
        <img
          id="herov2"
          className={css(styles.productCta)}
          src={bg}
          alt="Reviewed by Accessible 360"
        />
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.subheader)}>HYPE WILLIAMS X SLIM AARONS</p>
          <h1 className={css(styles.title)}>THE GOOD LIFE, REDEFINED</h1>
          <div className={css(styles.buttonWrapperHardcode)}>
            <a href="/editorial/thegoodliferedefined">
              <button className={css(styles.buttonHardcode)}>
                <p className={css(styles.buttonTextHardcode)}>
                  view behind the scenes
                </p>
              </button>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

const styles = StyleSheet.create({
  saturnContainer: {
    position: 'absolute',
    width: '100%',
    maxWidth: '1400px',
    transform: 'translateX(-50%)',
    height: 'auto',
    margin: '0px auto',
    zIndex: 15,
    background: 'none',
    top: '105vw',
    left: '50%',
    cursor: 'initial',

    [BREAKPOINTS.DESKTOP_LARGE]: {
      top: 'calc(780px + 63vw)',
    },

    [BREAKPOINTS.TABLET]: {
      top: '120vw',
      left: '0vw',
      transform: 'scale(0.8)',
    },

    [BREAKPOINTS.MOBILE]: {
      transform: 'scale(0.7)',
      left: '0vw',
    },
  },
  saturn: {
    width: '100%',
    overflow: 'hidden',
    border: 'none',
    background: 'none',
    height: 600, // Has to match the height of the css in the iFrame
  },
  iframeCheat: {
    bottom: '0',
    transform: 'translateY(-100%)',

    [BREAKPOINTS.SMALL_DESKTOP]: {
      bottom: 'initial',
      top: '100%',
      transform: 'translateY(-50%)',
    },

    [BREAKPOINTS.TABLET]: {
      transform: 'scale(0.75)',
      top: 'calc(100% - 15vh)',
    },
  },
  productCta: {
    display: 'block',
    filter: 'brightness(0.5)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  productCtaContainer: {
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 16)',
    // borderTop: '1px solid white',
    borderBottom: '1px solid white',

    [BREAKPOINTS.TABLET]: {
      height: '100vw',
    },
  },
  highTalesContainer: {
    position: 'relative',
    backgroundColor: black,
    width: '100%',
    paddingBottom: '8rem',
  },
  buttonWrapper: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.TABLET]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  button: {
    color: white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
    ':focus': {
      border: '1px solid white',
      backgroundColor: black,
    },
    ':hover': {
      backgroundColor: white,
      transition: 'background 0.3s ease-in-out',
    },
    ':hover > p': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },
  },
  buttonText: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {},
  },
  processCta: {
    position: 'relative',
    backgroundColor: black,
    padding: 0,
  },
  textWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',

    [BREAKPOINTS.TABLET]: {
      width: 'calc(100vw - 80px)',
    },
  },
  subheader: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.TABLET]: {
      fontSize: 8,
    },
  },
  subheaderposter: {
    position: 'relative',
    fontSize: 12,
    fontFamily: 'Titling Gothic FB Wide',
    color: white,
    lineHeight: '12px',
    textTransform: 'uppercase',
    paddingBottom: '20px',
    [BREAKPOINTS.SELECTOR]: {
      fontSize: 8,
    },
  },
  title: {
    color: white,
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    paddingBottom: '20px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  buttonWrapperHardcode: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      margin: '-1rem auto',
      transform: 'scale(0.75)',
    },
  },
  buttonHardcode: {
    color: black,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignSelf: 'center',
    height: convertToVW(123),
    width: '360px',
    maxWidth: '450px',
    maxHeight: '100px',
    minHeight: '70px',
    border: '1px solid white',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.3s ease-in-out',
    [BREAKPOINTS.FOOTER_BREAK_12]: {
      width: '450px',
    },
    [BREAKPOINTS.FOOTER_BREAK_95]: {
      width: '270px',
    },
  },
  buttonTextHardcode: {
    fontSize: 12,
    color: black,
    fontFamily: 'Titling Gothic FB Wide',
    [BREAKPOINTS.TABLET]: {},
  },
  carouselContainer: {
    position: 'relative',
    backgroundColor: black,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vw * 9 / 12)',
    marginBottom: 120,
    alignItems: 'center',
    borderTop: '1px solid white',
    [BREAKPOINTS.SMALL_DESKTOP]: {
      height: 'calc(100vh + 100px)',
    },
    [BREAKPOINTS.TABLET]: {
      height: '100vh',
    },
  },
  carousel: {
    maxWidth: '1400px',
    width: '100%',
    margin: 0,
    overflow: 'hidden',
    border: 'none',
    background: 'none',
    height: 'calc(100vw * 9 / 14)',
    [BREAKPOINTS.SMALL_DESKTOP]: {
      height: '100vh',
    },
    [BREAKPOINTS.TABLET]: {
      height: '100vh',
    },
    [BREAKPOINTS.MOBILE]: {
      height: '100vh',
    },
  },
});

export const query = graphql`
  query HomePageQuery {
    contentfulHomePage {
      topSection {
        backgroundTexture {
          fluid(maxWidth: 3000, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        backgroundVideo {
          file {
            url
          }
        }
        mainHeader
        topSubheader
        bottomSubheader
      }
      processCtaVideo {
        file {
          url
        }
      }
      productCtaImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      editorialCtaImage {
        fluid(maxWidth: 3000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default HomePage;
