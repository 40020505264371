import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';

import { black, white } from 'shared/colors';
import HighTalesVideo from './HighTalesVideo';
import { BREAKPOINTS } from 'shared/constants';

interface IHighTalesSection {
  contentfulHighTalesSection: {
    header: string;
    subheader: {
      subheader: string;
    };
    videos: {
      id: string;
      title: string;
      description: {
        description: string;
      };
      youtubeId: string;
    }[];
  };
}

const HighTales = () => {
  const {
    contentfulHighTalesSection: { header, subheader, videos },
  }: IHighTalesSection = useStaticQuery(graphql`
    query HighTalesSectionQuery {
      contentfulHighTalesSection {
        header
        subheader {
          subheader
        }
        videos {
          id
          title
          description {
            description
          }
          youtubeId
        }
      }
    }
  `);

  let videoSlides = videos.map(({ id, title, description, youtubeId }) => (
    <HighTalesVideo
      key={id}
      title={title}
      description={description.description}
      youtubeId={youtubeId}
    />
  ));

  return (
    <div className={css(styles.container)}>
      <h3 className={css(styles.h3)}>{header}</h3>
      <p className={css(styles.p)}>{subheader.subheader}</p>
      <div className={css(styles.flexContainer)}>{videoSlides}</div>
    </div>
  );
};

export default HighTales;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    overflow: 'hidden',
    backgroundColor: black,
    paddingTop: '100px',

    [BREAKPOINTS.MOBILE]: {
      paddingTop: '3rem',
    },
  },
  flexContainer: {},
  h3: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Compressed Standard',
    fontWeight: 'normal',
    fontSize: 70,
    zIndex: 1,
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
    },
  },
  p: {
    color: white,
    fontFamily: 'Titling Gothic FB Normal Regular',
    marginTop: '20px',
    fontSize: '12px',
    maxWidth: '800px',
    width: '90%',
    lineHeight: 1.5,
    marginBottom: '60px',
    zIndex: 1,
    textAlign: 'center',
    [BREAKPOINTS.MOBILE]: {
      marginTop: '.5rem',
      marginBottom: '4rem',
    },
  },
});
