import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import { Text, Font } from 'components/Text/Text';
import { white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import { convertToMobileVW, convertToVW } from 'shared/utils';

interface Props {
  title: string;
  description: string;
  cardText?: string;
  backgroundTexture: {
    fluid: FluidObject;
  };
}

const FlavorCard = ({
  title,
  description,
  cardText,
  backgroundTexture,
}: Props) => {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.nameContainer)}>
        <BackgroundImage
          className={css(styles.backgroundImage)}
          fluid={backgroundTexture.fluid}
          aria-hidden
        >
          <Text
            text={cardText}
            font={Font.TitlingGothicFBCompressedRegular}
            extraStyle={styles.cardText}
          />
        </BackgroundImage>
      </div>
      <div className={css(styles.cardInfo)}>
        <h4 className={css(styles.h4)}>{title}</h4>
        <p className={css(styles.p)}>{description}</p>
      </div>
    </div>
  );
};

export default FlavorCard;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'grid',
    width: '100%',
    height: 225,
    gridTemplateColumns: '50% 50%',
    borderTop: '1px solid white',
    [BREAKPOINTS.TABLET]: {
      gridTemplateColumns: '100%',
      height: 'auto',
      borderTop: 0,
      borderBottom: 0,
      marginBottom: '1.5rem',
    },
  },
  nameContainer: {
    borderRight: '1px solid white',
    [BREAKPOINTS.TABLET]: {
      border: '1px solid white',
      margin: '0 1rem',
    },
  },
  cardInfo: {
    padding: '1.75rem 1rem 1rem 1rem',
    [BREAKPOINTS.TABLET]: {
      padding: '1rem',
    },
  },
  h4: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Narrow Standard',
    fontWeight: 'normal',
    fontSize: '20px',
    zIndex: 1,
    marginBottom: '1rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: '12px',
    },
  },
  p: {
    color: white,
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontSize: '12px',
    lineHeight: 1.5,
    zIndex: 1,
    [BREAKPOINTS.MOBILE]: {
      fontSize: 12,
    },
  },
  backgroundImage: {
    display: 'grid',
    placeItems: 'center center',
    width: '100%',
    height: '100%',
    [BREAKPOINTS.TABLET]: {
      height: '28vw',
    },
  },
  cardText: {
    fontSize: convertToVW(70),
    [BREAKPOINTS.TABLET]: {
      fontSize: convertToMobileVW(70),
    },
  },
});
