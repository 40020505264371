import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'gatsby';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import { convertToVW, convertToMobileVW } from 'shared/utils';
const bg = require('images/home-bg.jpeg');

interface Props {
  mainHeader: string;
  topSubheader?: string;
  bottomSubheader?: string;
}

const PageTopSection = ({
  mainHeader,
  topSubheader,
  bottomSubheader,
}: Props) => {
  return (
    <section className={css(styles.container)}>
      <div className={css(styles.pageTopWrapper)}>
        <h1 className={css(styles.h1)}>{mainHeader}</h1>
        <div className={css(styles.pageTopCta)}>
          <h3 className={css(styles.ctaText)}>The Good Life, Redefined</h3>
          <Link to={`/editorial/thegoodliferedefined`}>
            <button className={css(styles.button)}>
              <span className={css(styles.buttonText)}>
                View Behind the Scenes
              </span>
            </button>
          </Link>
        </div>
      </div>
      <img
        id="hero-bg"
        className={css(styles.homeBg)}
        src={bg}
        alt="Reviewed by Accessible 360"
      />
      {topSubheader && (
        <h2 className={css(styles.subheader, styles.topSubheader)}>
          {topSubheader}
        </h2>
      )}

      {bottomSubheader && (
        <p className={css(styles.subheader, styles.bottomSubheader)}>
          {bottomSubheader}
        </p>
      )}
    </section>
  );
};

export default PageTopSection;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    color: white,
  },
  homeBg: {
    position: 'relative',
    width: '100%',
    top: '0',
    left: 0,
  },
  pageTopWrapper: {
    position: 'relative',
    marginTop: '120px',
    padding: '20px',
    paddingBottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#D9F7FD',

    [BREAKPOINTS.TABLET]: {
      marginTop: '90px',
      height: 'auto',
    },
  },
  h1: {
    fontFamily: 'Titling Gothic FB Narrow Standard',
    textTransform: 'uppercase',
    fontSize: '10vw',
    lineHeight: 1,
    zIndex: 10,
    color: black,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    width: '65vw',

    [BREAKPOINTS.DESKTOP_LARGE]: {
      fontSize: '160px',
      width: '1000px',
    },
  },
  subheader: {
    position: 'relative',
    fontFamily: 'Titling Gothic FB Compressed Standard',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    fontSize: '2.8vw',
    lineHeight: 1.2,
    width: convertToVW(1199),
    maxWidth: convertToVW(1199),
    zIndex: 10,
    [BREAKPOINTS.DESKTOP_LARGE]: {
      fontSize: '50px',
      width: '1000px',
    },
    [BREAKPOINTS.SA_INDEX_FIX]: {
      width: '80vw',
      fontSize: '5vw',
    },
  },
  pageTopCta: {
    marginTop: '40px',
    width: 'calc(100vw - 40px)',
    height: '122px',
    padding: '20px',
    position: 'relative',
    border: '1px solid #000000',
    backgroundColor: white,

    [BREAKPOINTS.SA_INDEX_FIX]: {
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      paddingBottom: '20px',
      height: 'auto',
      boxSizing: 'border-box',
    },
  },
  ctaText: {
    position: 'relative',
    left: 0,
    top: 'calc(50% + 8px)',
    transform: 'translateY(-50%)',
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textTransform: 'uppercase',
    fontSize: '70px',
    lineHeight: '70px',
    color: black,

    [BREAKPOINTS.SA_INDEX_FIX]: {
      top: 0,
      transform: 'initial',
      fontSize: '40px',
      lineHeight: 1,
    },
  },
  topSubheader: {
    display: 'none',
    marginTop: '40px',
    [BREAKPOINTS.MOBILE]: {
      marginTop: '2rem',
    },
  },
  bottomSubheader: {
    display: 'none',
    position: 'relative',
    marginTop: '40px',
    marginBottom: '20vw',
    [BREAKPOINTS.DESKTOP_LARGE]: {
      marginBottom: '320px',
    },
    [BREAKPOINTS.MOBILE]: {
      marginTop: '2rem',
    },
  },
  button: {
    position: 'absolute',
    textTransform: 'uppercase',
    width: 'auto',
    right: '20px',
    height: 'calc(100% - 40px)',
    backgroundColor: black,
    color: white,
    padding: '20px',
    top: '20px',
    cursor: 'pointer',
    border: `1px solid ${black}`,
    ':focus': {
      border: `1px solid ${black}`,
    },
    ':hover': {
      backgroundColor: white,
      transition: 'background 0.3s ease-in-out',
    },
    ':hover > span': {
      color: black,
      transition: 'color 0.3s ease-in-out',
    },
    [BREAKPOINTS.SA_INDEX_FIX]: {
      color: black,
      backgroundColor: white,
      position: 'relative',
      marginTop: '20px',
      marginLeft: '20px',
      width: '100%',
      height: '80px',

      border: `1px solid ${black}`,
      ':focus': {
        border: `1px solid ${black}`,
      },
      ':hover': {
        backgroundColor: black,
        transition: 'background 0.3s ease-in-out',
      },
      ':hover > span': {
        color: white,
        transition: 'color 0.3s ease-in-out',
      },
    },
  },
  buttonText: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Wide',
  },
});
