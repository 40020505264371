import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';

import { Text, Font } from 'components/Text/Text';
import TippyTooltip from 'components/TippyTooltip/TippyTooltip';
import { white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import { convertToMobileVW, convertToVW } from 'shared/utils';

interface Props {
  title: string;
  description: string;
  cardText?: string;
  backgroundTexture: {
    fluid: FluidObject;
  };
  extraStyles?: {};
  index: number;
}

const PotencyCard = ({
  title,
  description,
  cardText,
  backgroundTexture,
  extraStyles,
  index,
}: Props) => {
  return (
    <li
      className={css(styles.cardContainer)}
      style={{ gridColumn: `${index + 1}` }}
    >
      <BackgroundImage
        className={css(styles.backgroundImage)}
        fluid={backgroundTexture.fluid}
        aria-hidden
      >
        <Text
          text={cardText}
          font={Font.TitlingGothicFBCompressedRegular}
          extraStyle={styles.cardText}
        />
      </BackgroundImage>
      <TippyTooltip>
        <h3
          className={css(
            styles.h3,
            extraStyles && extraStyles,
            styles.underline
          )}
        >
          {title}
        </h3>
      </TippyTooltip>
      <p className={css(styles.description)}>{description}</p>
    </li>
  );
};

export default PotencyCard;

const styles = StyleSheet.create({
  cardContainer: {
    width: '28vw',
    height: 'fit-content',
    margin: '0 auto',
    listStyleType: 'none',
    [BREAKPOINTS.TABLET]: {
      width: '100%',
    },
  },
  backgroundImage: {
    display: 'grid',
    placeItems: 'center center',
    border: `1px solid ${white}`,
    width: '100%',
    height: 'calc(28vw * 1.05)',
    [BREAKPOINTS.TABLET]: {
      marginTop: convertToMobileVW(40),
      height: convertToMobileVW(211),
      width: '100%',
    },
  },
  h3: {
    fontFamily: 'Titling Gothic FB Narrow Standard',
    fontSize: '20px',
    marginTop: convertToVW(33),
    width: 'fit-content',
    [BREAKPOINTS.TABLET]: {
      marginTop: convertToMobileVW(33),
    },
    [BREAKPOINTS.MOBILE]: {
      marginTop: '5vw',
    },
  },
  description: {
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontSize: '12px',
    marginTop: convertToVW(36),
    lineHeight: 1.5,
    [BREAKPOINTS.TABLET]: {
      marginTop: convertToMobileVW(32),
    },
    [BREAKPOINTS.MATTE_MOBILE]: {},
  },
  cardText: {
    fontSize: '12px',
    [BREAKPOINTS.TABLET]: {
      fontSize: '12px',
    },
  },
  underline: {
    textDecoration: 'underline',
  },
});
