import React from 'react';
import { StyleSheet, css } from 'aphrodite';

import { white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';

interface Props {
  title: string;
  description: string;
  youtubeId: string;
}

const HighTalesVideo = ({ title, description, youtubeId }: Props) => {
  const youtubeUrl = `https://www.youtube.com/embed/${youtubeId}?rel=0`;

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.videoEmbed)}>
        <iframe
          className={css(styles.iFrame)}
          width="560"
          height="315"
          src={youtubeUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className={css(styles.videoInfo)}>
        <h4 className={css(styles.h4)}>{title}</h4>
        <p className={css(styles.p)}>{description}</p>
      </div>
    </div>
  );
};

export default HighTalesVideo;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '50% 50%',
    borderTop: '1px solid white',

    [BREAKPOINTS.TABLET]: {
      gridTemplateColumns: '100%',
    },
  },
  videoEmbed: {
    borderRight: '1px solid white',
    height: 'calc(50vw * (315 / 560))',
    [BREAKPOINTS.TABLET]: {
      borderRight: 0,
      height: 'calc(100vw * (315 / 560))',
    },
  },
  iFrame: {
    width: '100%',
    height: 'calc(50vw * (315 / 560))',
    marginBottom: 0,
    [BREAKPOINTS.TABLET]: {
      borderRight: 0,
      height: 'calc(100vw * (315 / 560))',
    },
  },
  videoInfo: {
    padding: '1rem',
  },
  h4: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Narrow Standard',
    fontWeight: 'normal',
    fontSize: 20,
    zIndex: 1,
    marginBottom: '1rem',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 16,
      marginTop: '1rem',
    },
  },
  p: {
    color: white,
    fontFamily: 'Titling Gothic FB Normal Regular',
    fontSize: '12px',
    lineHeight: 1.5,
    zIndex: 1,
    [BREAKPOINTS.MOBILE]: {
      fontSize: 12,
    },
  },
  video: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    maxWidth: 1306,
    height: 829,
    objectFit: 'cover',
    zIndex: 1,
  },
});
