import React, { useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import Img, { FluidObject } from 'gatsby-image';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './LogoCarousel.css';

const play = require('images/play.png');
const pause = require('images/pause.png');

import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';

interface LogoCarousel {
  contentfulLogoCarousel: {
    title: string;
    items: {
      title: string;
      logo: {
        fluid: FluidObject;
        description: string;
      };
      description: {
        description: string;
      };
      link: string;
    }[];
  };
}

const LogoCarousel = () => {
  const {
    contentfulLogoCarousel: { title, items },
  }: LogoCarousel = useStaticQuery(graphql`
    query LogoCarouselQuery {
      contentfulLogoCarousel {
        title
        items {
          title
          description {
            description
          }
          logo {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
          link
        }
      }
    }
  `);
  const sliderRef = useRef<Slider | null>(null);
  const [autoPlayOn, setAutoPlayOn] = useState(true);

  const onClick = () => {
    setAutoPlayOn(!autoPlayOn);

    if (!autoPlayOn) {
      sliderRef.current.slickPlay();
    } else {
      sliderRef.current.slickPause();
    }
  };

  const settings = {
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul className={css(styles.ul)}> {dots} </ul>
        <button className={css(styles.pause)} onClick={onClick}>
          {autoPlayOn ? (
            <img className={css(styles.pauseIcon)} alt="Pause" src={pause} />
          ) : (
            <img className={css(styles.pauseIcon)} alt="Play" src={play} />
          )}
        </button>
      </div>
    ),
    customPaging: () => (
      <button className={css(styles.carouselButton)} aria-hidden />
    ),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="logoCarousel">
      <div className={css(styles.container)}>
        <Slider
          ref={sliderRef}
          accessibility={true}
          focusOnSelect={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          centerMode={false}
          arrows={true}
          dots={true}
          swipe={true}
          autoplay
          autoplaySpeed={5000}
          {...settings}
        >
          {items.map(({ description, logo, link }) => {
            return (
              <div className={css(styles.logoItem)}>
                <Img
                  className={css(styles.logo)}
                  alt={logo.description}
                  fluid={logo.fluid}
                />
                <p className={css(styles.copy)}>{description.description}</p>
                <a className={css(styles.link)} href={link} target="_blank">
                  READ FULL ARTICLE
                </a>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default LogoCarousel;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden',
    background: 'linear-gradient(180deg, #000000 20.31%, #292929 100%)',
    paddingTop: '100px',
    paddingBottom: '85px',

    [BREAKPOINTS.MOBILE]: {
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
  },
  headline: {
    fontFamily: 'Titling Gothic FB Compressed Standard',
    textAlign: 'center',
    fontSize: '70px',
    maxWidth: '715px',
    textTransform: 'uppercase',
    margin: 'auto',
    marginBottom: '65px',
  },
  logo: {
    height: '40px',
    // maxWidth: '515px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '50px',
    [BREAKPOINTS.MOBILE]: {
      maxHeight: '100px',
    },
  },
  carouselButton: {
    // width: 100,
    // height: 50,
    // borderTop: `4px solid ${white}`,
    // [BREAKPOINTS.TABLET]: {
    //   width: 50,
    // },
  },
  link: {
    color: 'white',
    // textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Titling Gothic FB Narrow Regular',
    [BREAKPOINTS.MOBILE]: {
      fontSize: '12px',
    },
  },
  copy: {
    color: 'white',
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '35px',
    [BREAKPOINTS.MOBILE]: {
      fontSize: '12px',
    },
  },
  ul: {
    margin: 0,
  },
  logoItem: {
    width: '580px',
    textAlign: 'center',
    fontFamily: 'Titling Gothic FB Normal Standard',
    fontSize: '22px',
    lineHeight: '30px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      fontSize: '18px',
      lineHeight: '22.5px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  pause: {
    background: 'transparent',
    boxShadow: 'none',
    border: '0',
    display: 'flex',
    marginLeft: '6px',
    marginTop: '4px',
  },
  pauseIcon: {
    width: '17px',
  },
});
