import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import { black, white } from 'shared/colors';
import { BREAKPOINTS } from 'shared/constants';
import FlavorCard from './FlavorCard';
import { FlavorSection } from 'types/shared';
import { convertToVW, convertToMobileVW } from 'shared/utils';

const FlavorContainer = () => {
  const {
    contentfulFlavorSection: { title, subheader, flavorCards },
  }: FlavorSection = useStaticQuery(graphql`
    query FlavorSectionQuery {
      contentfulFlavorSection {
        title
        subheader {
          subheader
        }
        flavorCards {
          id
          title
          cardText
          backgroundTexture {
            fluid(maxWidth: 3000, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
        }
      }
    }
  `);

  return (
    <div className={css(styles.container)}>
      <h2 className={css(styles.hardCoded)}>
        Monogram marks a new chapter in cannabis defined by dignity, care and
        consistency. It is a collective effort to bring you the best, and a
        humble pursuit to discover what the best truly means.
      </h2>
      <h3 className={css(styles.h3)}>{title}</h3>
      <p className={css(styles.p)}>{subheader.subheader}</p>
      <ul className={css(styles.listContainer)}>
        {flavorCards.map(
          ({
            description: { description },
            id,
            title,
            cardText,
            backgroundTexture,
          }) => {
            return (
              <FlavorCard
                key={id}
                title={title}
                description={description}
                cardText={cardText}
                backgroundTexture={backgroundTexture}
              />
            );
          }
        )}
      </ul>
    </div>
  );
};

export default FlavorContainer;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    backgroundColor: black,
    color: white,
    width: '100%',
    height: 'fit-content',
    paddingTop: '250px',
    borderTop: `1px solid ${white}`,
    borderBottom: `1px solid ${white}`,
    zIndex: 10,
    margin: 0,

    [BREAKPOINTS.TABLET]: {
      paddingTop: '40vw',
    },

    [BREAKPOINTS.MOBILE]: {
      paddingTop: '60vw',
    },
  },
  hardCoded: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Compressed',
    fontWeight: 'normal',
    maxWidth: '800px',
    margin: '0 auto',
    fontSize: 50,
    zIndex: 1,
    marginTop: '60px',
    textAlign: 'center',
    [BREAKPOINTS.TABLET]: {
      maxWidth: 'calc(100vw - 40px)',
      fontSize: 24,
    },
  },
  h3: {
    color: white,
    textTransform: 'uppercase',
    fontFamily: 'Titling Gothic FB Compressed Standard',
    fontWeight: 'normal',
    fontSize: 70,
    zIndex: 1,
    marginTop: '60px',
    textAlign: 'center',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 40,
    },
  },
  p: {
    color: white,
    fontFamily: 'Titling Gothic FB Normal Regular',
    marginTop: '1.5rem',
    fontSize: '12px',
    maxWidth: '400px',
    lineHeight: 1.5,
    marginBottom: '100px',
    zIndex: 1,
    textAlign: 'center',
    margin: '0 auto',
    [BREAKPOINTS.TABLET]: {
      marginTop: '.5rem',
      marginBottom: '2.5rem',
      width: '90%',
    },
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5rem',
    paddingBottom: '0px',
    marginBottom: '0px',
    [BREAKPOINTS.TABLET]: {
      marginTop: 0,
    },
  },
});
